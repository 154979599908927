import * as React from 'react';
import {
  motion,
} from 'framer-motion';

import {
  Breakpoint,
  Lang,

  useDimensions,

  TextBlock,
} from '@bluecurrent/web-component-lib';

import useSanityData from '../../../../hooks/useSanityData';

import handleLocalUrlChange from '../../../../functions/handleLocalUrlChange';
import handleImageRefToUrl from '../../../../functions/handleImageRefToUrl';
import handleHeadingPrioritization from '../../../../functions/handleHeadingPrioritization';

import getBannerLandingImage from '../../../../services/Sanity/query/getImage/getBannerLandingImage';

import Setup from '../../../../config/Setup.config';

import Slider from './Slider';

export default function Landing(props) {
  const { i18n } = Lang.useTranslation();
  const size = useDimensions();
  const images = useSanityData(
    getBannerLandingImage(
      props.params.id,
      props.params.index,
      props.params.objectIndex,
    ),
  );

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;

  /* eslint-disable-next-line no-underscore-dangle */
  const image = handleImageRefToUrl(props.data.image.asset._ref);
  const carousel = images[props.params.layoutIndex]?.layout?.Components?.banner_landing?.carousel;

  return (
    <motion.div
      style={{
        display: 'flex',
        flexDirection: size.width < Breakpoint.sm ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        minHeight: size.width < Breakpoint.sm ? '50vh' : 560,
        position: 'relative',
      }}
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        ease: 'easeInOut',
        stiffness: 100,
      }}
      viewport={{
        once: true,
      }}
    >
      <div
        style={{
          height: 'auto',
          width: size.width < Breakpoint.sm ? '100%' : '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        {
          (
            props.data.text_block.title !== undefined
            || props.data.text_block.text !== undefined
          ) && (
            <TextBlock
              title={{
                content: props.data.text_block.title !== undefined && (
                  props.data.text_block.title[lang]
                ),
                portableText: props.data.text_block.title !== undefined,
                heading: handleHeadingPrioritization(props.params.index),
              }}
              text={{
                content: props.data.text_block.text !== undefined && (
                  props.data.text_block.text[lang]
                ),
                portableText: props.data.text_block.text !== undefined,
              }}
              align={size.width < Breakpoint.sm ? props.data.text_block.align : 'left'}
              buttons={
                (props.data.text_block.buttons !== undefined)
                && props.data.text_block.buttons.map((item) => ({
                  /* eslint-disable no-underscore-dangle */
                  id: item._key,
                  text: item.link.text === 'custom' ? item.link.custom_text[lang] : item.link.text[lang],
                  colorScheme: item.colorscheme,
                  onClick: () => window.open(handleLocalUrlChange(item.link.url), item.link.target),
                }))
              }
            />
          )
        }
      </div>
      <div
        style={{
          height: '100%',
          minHeight: 'auto',
          maxHeight: size.width < Breakpoint.sm ? 'inherit' : 560,
          width: size.width < Breakpoint.sm ? '100%' : '50%',
          position: size.width < Breakpoint.sm ? 'relative' : 'absolute',
          right: 0,
        }}
      >
        {
          size.width < Breakpoint.sm
            ? (
              <div
                style={{
                  height: 300,
                  width: '100%',
                  backgroundImage: carousel !== undefined && `url("${carousel[0]}?q=50")`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  borderRadius: 10,
                  marginBottom: 15,
                }}
              />
            ) : (
              <Slider
                image={image}
                carousel={carousel}
              />
            )
        }
      </div>
    </motion.div>
  );
}
