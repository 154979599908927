import * as React from 'react';
import {
  motion,
} from 'framer-motion';

import {
  Lang,
  Colors,
  Breakpoint,

  useDimensions,

  TextBlock,
  Confetti,
} from '@bluecurrent/web-component-lib';

import useSanityData from '../../../hooks/useSanityData';

import handleLocalUrlChange from '../../../functions/handleLocalUrlChange';
import handleHeadingPrioritization from '../../../functions/handleHeadingPrioritization';

import getBannerImage from '../../../services/Sanity/query/getImage/getBannerImage';

import Setup from '../../../config/Setup.config';

export default function DefaultBanner(props) {
  const { i18n } = Lang.useTranslation();
  const size = useDimensions();
  const image = useSanityData(
    getBannerImage(
      props.params.id,
      props.params.index,
      props.params.objectIndex,
    ),
  );

  const ref = React.useRef();

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;

  const src = image[props.params.layoutIndex]?.layout?.Components?.banner?.src;

  const [height, setHeight] = React.useState('100%');

  const getHeight = () => {
    if (ref.current.clientHeight !== null) setHeight(ref.current.clientHeight);
  };

  React.useEffect(() => {
    getHeight();
  }, [size]);

  React.useEffect(() => {
    window.addEventListener('resize', getHeight);

    return () => window.removeEventListener('resize', getHeight);
  }, []);

  return (
    <motion.div
      style={{
        height: 'auto',
        minHeight: 350,
        width: '100%',
        backgroundColor: Colors.BLUE,
        borderRadius: 10,
        display: 'flex',
        flexDirection: size.width < Breakpoint.sm ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        overflow: 'hidden',
      }}
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        ease: 'easeInOut',
        stiffness: 100,
      }}
      viewport={{
        once: true,
      }}
    >
      <div
        ref={ref}
        style={{
          marginTop: -15,
          width: size.width < Breakpoint.sm ? '100%' : '50%',
          height: '100%',
          paddingTop: size.width < Breakpoint.sm ? 45 : 30,
          paddingBottom: size.width < Breakpoint.sm ? 45 : 30,
          paddingLeft: size.width < Breakpoint.sm ? 25 : 45,
          paddingRight: size.width < Breakpoint.sm ? 25 : 45,
        }}
      >
        {
          (
            props.data.text_block.title !== undefined
            || props.data.text_block.text !== undefined
          ) && (
            <TextBlock
              title={{
                content: props.data.text_block.title !== undefined && (
                  props.data.text_block.title[lang]
                ),
                portableText: props.data.text_block.title !== undefined,
                color: Colors.WHITE,
                fontFamily: 'Caveat',
                fontWeight: 100,
                fontSize: '3.5em',
                heading: handleHeadingPrioritization(props.params.index),
              }}
              text={{
                content: props.data.text_block.text !== undefined && (
                  props.data.text_block.text[lang]
                ),
                portableText: props.data.text_block.text !== undefined,
                color: Colors.WHITE,
              }}
              align={size.width < Breakpoint.sm ? (props.data.text_block.align ?? 'left') : 'left'}
              buttons={
                (props.data.text_block.buttons !== undefined)
                && props.data.text_block.buttons.map((item) => ({
                  /* eslint-disable no-underscore-dangle */
                  id: item._key,
                  text: item.link.text === 'custom' ? item.link.custom_text[lang] : item.link.text[lang],
                  colorScheme: item.colorscheme,
                  onClick: () => window.open(handleLocalUrlChange(item.link.url), item.link.target),
                }))
              }
            />
          )
        }
      </div>
      <div
        style={{
          width: size.width < Breakpoint.sm ? '100%' : '50%',
          height: height + 100,
          minHeight: size.width < Breakpoint.sm ? 250 : 'auto',
          backgroundImage: src !== undefined && `url('${src}?q=50')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            left: 0,
            background: `linear-gradient(${size.width < Breakpoint.sm ? 180 : 45}deg, rgba(0,152,219,1) 0%, rgba(0,152,219,0) ${size.width < Breakpoint.sm ? 50 : 70}%)`,
          }}
        />
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: size.width < Breakpoint.sm ? '100%' : '40%',
            left: 0,
            background: `linear-gradient(${size.width < Breakpoint.sm ? 125 : 90}deg, rgba(0,152,219,1) 0%, rgba(0,152,219,0) 100%)`,
            opacity: size.width < Breakpoint.sm ? 0.7 : 1,
          }}
        />
        <Confetti
          type="DotsPath"
          height={1000}
          width={1000}
          color={Colors.WHITE}
          position="absolute"
          transform="rotate(100deg)"
          right={-500}
          top={-250}
        />
      </div>
    </motion.div>
  );
}
