import * as React from 'react';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';

import {
  Breakpoint,
  Colors,

  useDimensions,

  Image,
  Confetti,
} from '@bluecurrent/web-component-lib';

const Item = (props) => (
  <motion.div
    style={{
      height: '100%',
      minWidth: props.width,
      width: props.width,
      position: 'absolute',
      whiteSpace: 'nowrap',
      right: props.active
        ? -50
        : `calc(-${props.width}px - 100px)`,
      transition: 'right 0.8s ease-in-out',
    }}
    initial={{
      opacity: 0,
    }}
    animate={{
      opacity: 1,
    }}
    transition={{
      delay: 0.1,
      ease: 'linear',
    }}
  >
    <Image
      id={props.data.id}
      src={`${props.data.background.src}?fit=crop&auto=format&q=50`}
      width={props.size.width < Breakpoint.lg ? 1200 : '140%'}
      height={props.size.width < Breakpoint.lg ? 1200 : '140%'}
      wrapper={props.data.background.wrapper}
    />
  </motion.div>
);

export default function Slider(props) {
  const size = useDimensions();

  const ref = React.useRef(null);

  const [width, setWidth] = React.useState('100%');
  const [active, setActive] = React.useState(0);
  const [data, setData] = React.useState([]);

  const getDimensions = () => {
    if (ref.current.clientWidth !== null) setWidth(ref.current.clientWidth);
  };

  React.useEffect(() => {
    getDimensions();
  }, [width]);

  React.useEffect(() => {
    window.addEventListener('resize', getDimensions);

    return () => window.removeEventListener('resize', getDimensions);
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      if (active === 2) return setActive(0);
      return setActive(active + 1);
    }, 8000);
  }, [active]);

  React.useEffect(() => {
    let array = [];

    if (props.carousel !== undefined) {
      array = props.carousel.map((item, index) => ({
        id: index,
        background: {
          src: item,
          wrapper: 'Figure',
        },
      }));
    }

    setData(array);
  }, [props.carousel]);

  return (
    <div
      ref={ref}
      style={{
        height: '100%',
        width: '100%',
        position: 'relative',
        right: -100,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      {
        size.width >= Breakpoint.sm && (
          <div
            style={{
              background: 'linear-gradient(220deg, rgba(229,244,251,1) 0%, rgba(229,244,251,0) 75%, rgba(229,244,251,0) 100%)',
              height: '100%',
              width: '130%',
              position: 'absolute',
              zIndex: 1,
              right: -50,
              bottom: 0,
            }}
          />
        )
      }
      <AnimatePresence>
        {
          props.image !== undefined && (
            <motion.div
              style={{
                height: '75%',
                width: '100%',
                minWidth: 500,
                backgroundImage: props.image !== undefined && `url('${props.image}?w=600&h=600&auto=format&q=100')`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                position: 'absolute',
                bottom: 0,
                right: '-20%',
                zIndex: 2,
              }}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{
                delay: 0.1,
                ease: 'linear',
              }}
            />
          )
        }
      </AnimatePresence>
      {
        size.width >= Breakpoint.fd && (
          <Confetti
            position="absolute"
            type="DotsPath"
            right={size.width < Breakpoint.md ? -950 : -800}
            height={1300}
            width={1300}
            top={-150}
            color={Colors.WHITE}
            zIndex={1}
            opacity={1}
            transform="rotate(160deg)"
          />
        )
      }
      {
        data.map((item, index) => (
          <React.Fragment
            key={item.id}
          >
            <Item
              data={item}
              size={size}
              width={width}
              index={index}
              active={active === item.id}
            />
          </React.Fragment>
        ))
      }
    </div>
  );
}
