import * as React from 'react';

import Default from './Default';
import Landing from './Landing';

export default function Banner(props) {
  const renderHandler = () => {
    switch (props.type) {
      case 'landing':
        return (
          <Landing
            data={props.data}
            params={props.params}
          />
        );
      default:
        return (
          <Default
            data={props.data}
            params={props.params}
          />
        );
    }
  };

  return renderHandler();
}
