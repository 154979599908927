const getBannerImage = (
  id,
  layoutIndex,
  objectIndex,
) => `
  *[_id == "${id}"] {
    layout[${layoutIndex}] {
      Components[${objectIndex}] {
        banner {
          'src': image.asset->url
        }
      }
    }
  }
`;

export default getBannerImage;
