const getBannerLandingImage = (
  id,
  layoutIndex,
  objectIndex,
) => `
  *[_id == "${id}"] {
    layout[${layoutIndex}] {
      Components[${objectIndex}] {
        banner_landing {
          'image': image.asset->url,
          'carousel': carousel[].asset->url
        }
      }
    }
  }
`;

export default getBannerLandingImage;
